import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { DIRECTIONS } from '../../constants';

const menuStyles = ({ theme }) => css`
  label: dropdown__menu;
  list-style: none;
  box-shadow:
    0 0 0 1px var(--cui-border-subtle),
    0 0 1px 0 var(--cui-border-subtle),
    0 2px 2px 0 var(--cui-border-subtle);
  background-color: var(--cui-bg-normal);
  border-radius: ${theme.borderRadius.mega};
  color: var(--cui-fg-normal);
  width: 100%;
  max-height: 0;
  overflow-y: scroll;
  position: absolute;
  transition: max-height ${theme.transitions.default};
  visibility: hidden;
  z-index: 10;
`;

const menuDirectionUpStyles = ({ direction }) =>
  direction === DIRECTIONS.UP &&
  css`
    bottom: 100%;
  `;

const menuDirectionDownStyles = ({ direction }) =>
  direction === DIRECTIONS.DOWN &&
  css`
    top: 100%;
  `;

const menuOpenStyles = ({ isOpen }) =>
  isOpen &&
  css`
    label: --open;
    visibility: visible;
    max-height: 265px;
  `;

const Menu = styled('ul')(
  menuStyles,
  menuDirectionUpStyles,
  menuDirectionDownStyles,
  menuOpenStyles,
);

export default Menu;
